<template>
  <div class="m-grid p-common-rule">
    <section class="m-grid-search">
       <znl-input
        border
        width="150px"
        placeholder="搜索规则名称"
        clearable
        class="m-margin--right-10"
        v-model="searchData.Name"
      >
      </znl-input>
       <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
      <el-button class="m-grid-btn" type="primary" @click="onAdd" v-can="'config_common:add'"
        >新增</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
      >
      </grid>
    </section>
  </div>
</template>

<script>
import { columns } from "./modules/commonConfig/config";
import { getCommonConfigList, deleteCommonConfig } from "@/api/config";
import { dateNameArr } from "./modules/attribute/dataMap";
import{searchDataMap,listDataMap}from "./modules/commonConfig/dataMap";
//import {commonRuleClientOptions } from "./modules/commonRule/config";

export default {
  name: "configCommonConfig",
  components: {},
  data() {
    return {
      columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },

      searchData: {
        Name: null,
      },
    };
  },
  methods: {
    async onSearch(page = 1) {

      const { pagination ,searchData} = this;
      const sendData = {
        Page: page,
        Limit: pagination.size,
      };

    Object.entries(searchDataMap).forEach(([key, value]) => {
        sendData[value] = searchData[key];
      });
      const data = await getCommonConfigList(sendData); // 请求接口


      pagination.page = data.Page;
      pagination.total = data.Total;
      const seaPoolItems = data.Items;

      let newItems = [];

      if (seaPoolItems) {
        newItems= this.getListData(seaPoolItems,listDataMap)
        newItems.forEach((item, index) => {
          item.CreatedTime= this.formatDate(item.CreatedTime, 'yyyy-MM-dd hh:mm')
          item.RowIndex = index + 1;
        });

      }
      this.itemSource = newItems;
    },
    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(columns);
    },
    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const editBtn = {
        text: "编辑",
        click(params) {
          self.onOpenEdit(params.data);
        },
      };
      const deleteBtn = {
        text: "删除",
        click(params) {
          self.onOpenDelete(params.data);
        },
      };

      if(this.can('config_common:edit')) {
        buttons.push(editBtn);
      }
      if( this.can('config_common:delet')){
        buttons.push(deleteBtn);
      }

      return buttons;
    },
    onOpenDelete(data) {
      const params = {
        Id: Number(data.SeaID),
      };
      this.$confirm("此操作将永久删除该公海配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteCommonConfig(params);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onOpenEdit(data) {
      this.$router.push({
        name: "CommonConfigEdit",
        query: {
          id: data.SeaID,
        },
      });
    },
    onAdd() {
      this.$router.push({
        name: "CommonConfigAdd",
      });
    },
  },
  created() {
    this.onSearch();
  },
  mounted() {
    this.initColumns();
    this.$EventBus.$on('sendReloadMsg',(msg)=>{
      console.log(msg);//这是组件A发送的消息！
      this.onSearch();
    })
  },
};
</script>

<style>
</style>
