const listDataMap = {
  "id": 'DictItemID',
  'attributeName': 'ItemName',
  'attributeValueNum': 'Count',
  'createDate': 'CreatedAt',
  'createMan': 'CreateUserName',
  'updateDate': 'UpdatedAt',
  'updateMan': 'UpdateUserName',
  'isUse': 'IsActive',
  'isReadonly': 'IsReadonly',
  'isSystem':'IsSystem',
  'Multiple': 'Multiple',
}
const editListMap = {
  id: 'DictItemLineID',
  attributeValueName: 'ItemName',
  attributeValue: 'ItemCode',
  createDate: "CreatedAt",
  createName: 'CreateUserName',
  updateDate: 'UpdatedAt',
  updateName: 'UpdateUserName',
  isUse: 'IsActive',
  isReadonly: 'IsReadonly',
	Multiple: 'Multiple',
}

const paramsListMap = {
  id: 'DictItemLineID',
  attributeValueName: 'ItemName',
  attributeValue: 'ItemValue',
  isUse: 'IsActive',
  isReadonly: 'IsReadonly',
	Multiple: 'Multiple',
}


const editItemMap = {
  id: 'DictItemID',
  attributeName: 'ItemName',
  isUse: "IsActive",
  isReadonly: 'IsReadonly',
  itemSource: 'DictItemLines',
	Multiple:"Multiple"
}


const searchDataMap = {
  'attributeName': 'ItemName'
}

const dateNameArr = [
  'createDate',
  'updateDate',
]

export {
  listDataMap,
  editListMap,
  dateNameArr,
  editItemMap,
  searchDataMap,
  paramsListMap
}
