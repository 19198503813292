
const columns = [{
    field: 'RowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c'
  },
  {
    field: 'Name',
    headerName: '公海名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UpStreamName',
    headerName: '上游公海',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'DownStreamName',
    headerName: '下游公海',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CustomerType',
    headerName: '适用客户类型',
    editable: false,
    //dataMap: commonRuleClientOptions,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      let content = params.value
      if (value === "AddInquiry") {
        content = '新增询价客户'
      } else if (value === "Commonly") {
        content = '一般客户'
      } else if (value === "StressFollow") {
        content = '重点跟进客户'
      } else if (value === "StressProtect") {
        content = '重点保护客户'
      } else if (value === "Assist") {
        content = '协助客户'
      } else if (value === "Subordinate") {
        content = '下属客户'
      }
      return content
    },
  },
  {
    field: 'Description',
    headerName: '公海说明',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CreatedTime',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
const ruleColumns = [{
    field: 'RuleName',
    headerName: '规则名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'RuleType',
    headerName: '规则类型',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'RuleDescription',
    headerName: '规则说明',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'LimitDays',
    headerName: '限定天数',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CustomerType',
    headerName: '适用客户类型',
    editable: false,
    //dataMap: commonRuleClientOptions,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      let content = params.value
      if (value === "AddInquiry") {
        content = '新增询价客户'
      } else if (value === "Commonly") {
        content = '一般客户'
      } else if (value === "StressFollow") {
        content = '重点跟进客户'
      } else if (value === "StressProtect") {
        content = '重点保护客户'
      } else if (value === "Assist") {
        content = '协助客户'
      } else if (value === "Subordinate") {
        content = '下属客户'
      }
      return content
    },
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
const userColumns = [{
    field: 'UserName',
    headerName: '人员名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'DeptName',
    headerName: '所属部门',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'LimitQty',
    headerName: '捞取数量限制',
    editable: true,
    width:180,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
const allUserDempercolumns = [
  {
    field: "UserName",
    headerName: "人员名称",
    editable: false,
    cellClass: "t-c",
  },
  {
    field: "DeptName",
    headerName: "所属部门",
    editable: false,
    cellClass: "t-c",
  },
];
export {
  columns,
  ruleColumns,
  userColumns,
  allUserDempercolumns
}
