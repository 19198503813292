const listDataMap = {
  // id: 'RuleID',
	SeaID: 'SeaID',
  Name: 'Name',
  Description:'Description',
  CreatedTime:'CreatedTime',
  UpStreamName:'UpStreamName',
  DownStreamName:'DownStreamName',
  GCompanyID:'GCompanyID',
  CustomerType:'CustomerType',
	IsPreset: 'IsPreset',
  // commonRuleType: 'RuleType',
  // commonRuleRemark: 'RuleDescription',
  // commonRuleDay: 'LimitDays',
  // commonRuleClientType: 'CustomerType',
}
const searchDataMap = {
  Name: "Name"
}

const autoDataTypeMap = [{
    key: 1,
    value: '天'
  },
  {
    key: 2,
    value: '周'
  },
  {
    key: 3,
    value: '月'
  }
]
const autoAssignMap = [{
    key: 1,
    value: '随机'
  },
  {
    key: 2,
    value: '按最短滞留时间'
  },
  {
    key: 3,
    value: '按最长滞留时间'
  }
]
export {
  listDataMap,
  autoDataTypeMap,
  autoAssignMap,
  searchDataMap
}
